import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to travel</title>
);

export const TravelPage = ({ data }: PageProps<Queries.FoodVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with travel videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to travel in China and beyond. We have{" "}
              <Link to="/">lots of other videos</Link> if you&apos;re interested
              in other topics.
            </p>
            <p>
              Traveling in China is a unique experience that offers a unique
              insight into a culture and language that is both ancient and
              modern. With its long history and diverse culture, China has
              something to offer every traveler. From ancient cities to modern
              metropolises, there is something for everyone to explore.
            </p>

            <p>
              For those learning Chinese, the country&apos;s vibrant culture and
              language can be explored through videos. From listening to locals
              in Beijing&apos;s bustling markets to learning how to order food
              in a restaurant, these videos are an excellent way to help you
              practice your listening and pronunciation skills.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default TravelPage;

export const pageQuery = graphql`
  query TravelVideos {
    allVideosYaml(filter: { topics: { in: "travel" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
